* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body,
html {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    color: #333;
    font-family: 'Arial', sans-serif;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixed-header, .fixed-footer {
    width: 100%;
    background: #333;
    color: #fff;
    padding: 15px 0;
}

.fixed-header {
    position: fixed;
    top: 0;
}

.fixed-footer {
    position: fixed;
    bottom: 0;
}

.fixed-header .container, .fixed-footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

nav ul, .ft-legal ul {
    list-style: none;
    display: flex;
    gap: 20px;
}

nav a, .ft-legal a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

nav a:hover, .ft-legal a:hover {
    color: #007BFF;
}

.logo {
    width: 50px;
    height: auto;
}

.title {
    margin-top: 80px;
    text-align: center;
}

.wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.wrapper p {
    color: #666;
    line-height: 1.6;
}

footer {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}

footer a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
}

footer a:hover {
    color: #007BFF;
}

.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.not-found .title {
    margin-top: 0; /* Override the default margin-top for the title */
}